<template>
  <div class="box">
    <!-- <top-bar :title='this.$route.query.title' :left="true"></top-bar> -->
    <div class="workGoal">
      <van-cell-group border>
        <div style="height: 50px; display: flex; align-items: center">
          <div style="
              height: 16px;
              width: 3px;
              background-color: #387df4;
              margin-left: 20px;
            "></div>
          <div style="
              font-size: 18px;
              color: black;
              font-weight: 600;
              margin-left: 5px;
            ">
            {{ this.$route.query.categoryName }}任务详情
          </div>
        </div>
        <van-form input-align="right">
          <van-field v-model="workInfo.title" label="任务标题" :readonly ="disable" />
          <van-field v-model="categorylabel" label="目标类别" @click="categoryShow = true &&disable == false" :readonly="disable" />
          <van-field v-model="workInfo.getUser" label=" 认领人" type="textarea" :readonly="disable" />
          <van-field  v-if="workInfo.finishStatus == 0" value="已完成" label="完成状态" :readonly="true" />
          <van-field  v-if="workInfo.finishStatus == 1" v-model="finishStatus" label="完成状态" @click="finishStatusShow = true &&disable == false" :readonly="disable" />
          <van-field  v-if="workInfo.finishStatus == 0" v-model="workInfo.finishTime" label="完成时间" :readonly="disable" />
          <van-field  v-if="workInfo.finishStatus == 1" v-model="workInfo.finishTime" label=" 预计完成时间" :readonly="disable" @click="timeShow = true && disable == false" />
          <van-field v-model="workInfo.workDesc" rows="2" autosize label="任务详情描述" type="textarea" placeholder="请输入留言" :readonly="disable" />
          <!-- <div v-for="item in workInfo.fileMessages" :key="item.id" style="margin: 10px 10px">
            <img style="width: 100px; height: 100px" :src="item.objectUrl" alt="" />
          </div> -->
          <div  class="uploadBox">
            <van-uploader v-model="fileList" :deletable="false" :show-upload="false" />
          </div>
        </van-form>
        <div class="btn">
          <van-button v-if="workInfo.finishStatus == 1 && disable == true" round block type="info" @click="onMobify">编辑</van-button>
          <van-button v-if="workInfo.finishStatus == 1 && disable == false" round block type="info" @click="saveDate">保存</van-button>
        </div>
      </van-cell-group>
    </div>
    <van-popup v-model="timeShow" position="bottom" >
      <van-datetime-picker type="date" title="选择年月日" @confirm="timeConfirm" @cancel="timeShow = false" />
    </van-popup>
    <van-popup v-model="finishStatusShow" position="bottom" >
      <van-picker title="请选择" show-toolbar :columns="selectDate.finishStatus" value-key="label" @confirm="statusConfirm" @cancel="finishStatusShow = false" />
    </van-popup>
    <van-popup v-model="categoryShow" position="bottom" >
      <van-picker title="请选择" show-toolbar :columns="selectDate.menuOption1" value-key="label" @confirm="categoryfirm" @cancel="categoryShow = false" />
    </van-popup>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import { formatterDate } from "@/utils/utils";

export default {
  components: {
    topBar,
  },
  data() {
    return {
      disable: true,
      categoryShow: false,
      workInfo: {},
      timeShow: false,
      finishStatusShow: false,
      finishStatus: '',
      category: '',
      categorylabel: '',
      timer: "",
      selectDate: {
        finishStatus: [
          { label: "已完成", value: "0" },
          { label: "未完成", value: "1" },
        ],
        menuOption1: [
          { label: "近期工作", value: "0" },
          { label: "今年工作", value: "1" },
          { label: "五年工作", value: "2" },
        ],
      },
      fileList: []
    };
  },
  methods: {
    getDataInfo(userId) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl("/wxapp/app/appjqworktarget/info/" + userId),
        method: "post",
      }).then(
        ({ data }) => {
          if (data.code == 0) {
            // 加载状态结束
            this.workInfo = data.appJqWorkTarget;
            this.finishStatus = this.workInfo.finishStatusName
            this.categorylabel = this.workInfo.categoryName
            if (data.appJqWorkTarget.fileMessages) {
              data.appJqWorkTarget.fileMessages.map(item => {
                this.fileList.push({ url: item.objectUrl })
              })
            }
            this.loading = false;
            this.$toast.clear();
          }
          this.$toast.clear();
        },
        (err) => {
          this.$toast.clear();
        }
      );
    },
    onMobify() {
      this.disable = false;
    },
    saveDate() {
      let workInfo = this.workInfo;
      let finishStatus = this.finishStatus
      let category = this.category
      this.$http({
        url: this.$http.adornUrl("/wxapp/app/appjqworktarget/save"),
        method: "post",
        data: this.$http.adornData({
          id: workInfo.id,
          title: workInfo.title,
          category: category,
          getUser: workInfo.getUser,
          finishStatus: finishStatus == '完成' ? 0 : 1,
          finishTime: workInfo.finishTime,
          workDesc: workInfo.workDesc,
          newFileMessages: workInfo.newFileMessages,
        }),
      }).then(({ data }) => {
        if (data.code == 0) {
          // 加载状态结
          this.$notify({ type: "success", message: "提交成功" });
        } else {
          this.$notify({ type: "danger", message: "提交失败" });
        }
      });
      this.timer = setTimeout(() => {
        this.$router.go(-1);
      }, 1000);
    },
    timeConfirm(value) {
      let a = formatterDate(value);
      this.workInfo.finishTime = a;
      this.timeShow = false;
    },
    categoryfirm(value) {
      this.category = value.value
      this.categorylabel = value.label
      this.categoryShow = false
    },
    statusConfirm(value) {
      this.finishStatus = value.label;
      this.finishStatusShow = false;
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  mounted() {
    let userId = this.$route.query.userId;
    this.getDataInfo(userId);
  },
};
</script>

<style scoped>
.notice {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 0 0 100px 0;
  background-color: #fff;
}
.workGoal {
  width: 100%;
  background-color: #fff;
}
.content {
  width: 100%;
  font-size: 26px;
  color: #646566;
  border: 0;
}
.btn {
  position: fixed;
  bottom: 10px;
  left: 5%;
  width: 90%;
}
</style>
